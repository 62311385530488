export const DROPDOWN_CONTENT = [
  'Twitter',
  'Instagram',
  'Tiktok',
  'Google',
  'YouTube',
  'Friend / Referral',
  'Product Hunt',
];

export const OPTION_OTHER = 'Other';
