import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useState } from 'react';
import styles from '@/styles/Onboarding.module.css';
import type { FormEvent } from 'react';
import { shuffleContents } from '@/utils/shuffleArray';
import { Text, Button, cn } from '@typedream/ui';
import { CaretDown } from 'phosphor-react';
import { UnfoldMore } from '@styled-icons/material';
import formStyle from '../FormStyle.module.css';
import { DROPDOWN_CONTENT, OPTION_OTHER } from './constant';
import type { SignupFormPropsType } from './type';

function SignupForm({
  email,
  emailDisabled = false,
  errEmail,
  errSource,
  inputSource,
  inputSourceSpecific,
  loading,
  onSubmit,
  setEmail,
  setInputSource,
  setInputSourceSpecific,
  setshowSourceField,
  showEmailField,
  showSourceField,
}: SignupFormPropsType) {
  const [dropdownActive, setDropdownActive] = useState(false);
  const dropdownOptions = [...shuffleContents(DROPDOWN_CONTENT), OPTION_OTHER];

  const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <form onSubmit={handleOnSubmit}>
      {showEmailField && (
        <>
          <Text>Email</Text>
          <div
            className={cn(
              'mt-2 mx-0 mb-5 py-[15px] px-4 border border-border rounded-md border-solid',
              'flex items-center justify-between w-full bg-background',
              {
                'border-none bg-gray-100': emailDisabled,
                'border-red-100 border-2': errEmail,
              }
            )}
          >
            <input
              className={cn(
                formStyle.input,
                'bg-background placeholder:text-secondary text-primary'
              )}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setshowSourceField(Boolean(e.target.value));
              }}
              type="email"
              placeholder="Enter your email address to continue..."
              required
              disabled={emailDisabled}
            />
          </div>
        </>
      )}

      {showSourceField && (
        <div className={`${dropdownActive ? 'min-h-[25vh] mb-4' : ''}`}>
          <div className="mb-8 text-primary">
            <Text weight="medium" className="mt-8 mb-2">
              How did you hear about us?
            </Text>
            <DropdownMenu.Root open={dropdownActive} onOpenChange={setDropdownActive}>
              <DropdownMenu.Trigger
                className={cn(
                  'mt-2 flex items-center justify-between w-full bg-background',
                  'py-[15px] px-4 border border-border rounded-md border-solid w-full'
                )}
              >
                <Text
                  size="sm"
                  weight="medium"
                  variant="mute"
                  style={{
                    color: 'inherit !important',
                  }}
                >
                  {inputSource || 'Select a source'}
                </Text>
                <UnfoldMore size={16} />
              </DropdownMenu.Trigger>
              <DropdownMenu.Portal>
                <DropdownMenu.Content
                  asChild
                  sideOffset={12}
                  style={{ zIndex: 15, width: 'var(--radix-dropdown-menu-trigger-width)' }}
                >
                  <div
                    className={cn(
                      'flex flex-col w-full border border-border border-solid bg-background',
                      'rounded-md shadow-xl overflow-auto max-h-[150px] items-stretch min-w-[100%]'
                    )}
                  >
                    {dropdownOptions.map((c) => (
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      <a
                        href="#"
                        className={cn('p-2 w-full hover:bg-background-surface text-primary', {
                          'bg-background-accent text-white': inputSource === c,
                          'bg-background': inputSource !== c,
                        })}
                        onClick={() => {
                          setInputSource(c);
                          setDropdownActive(false);
                        }}
                      >
                        <Text size="sm" className="text-inherit">
                          {c}
                        </Text>
                      </a>
                    ))}
                  </div>
                </DropdownMenu.Content>
              </DropdownMenu.Portal>
            </DropdownMenu.Root>
          </div>

          {inputSource === OPTION_OTHER && (
            <div className="mb-8">
              <Text weight="medium" className="mt-8 mb-2">
                Please Specify
              </Text>
              <div className={formStyle.inputContainer}>
                <input
                  className={cn(
                    formStyle.input,
                    'bg-background placeholder:text-secondary text-primary'
                  )}
                  value={inputSourceSpecific}
                  onChange={(e) => setInputSourceSpecific(e.target.value)}
                  type="text"
                  placeholder="i.e. Youtube"
                  required
                />
              </div>
            </div>
          )}
        </div>
      )}

      <Button
        type="submit"
        data-testid="Login_submit_email"
        className="w-full"
        isLoading={loading}
        isDisabled={!email && !inputSource}
      >
        Sign up
      </Button>
    </form>
  );
}

export default SignupForm;
