import GoogleSignInButton from '@/components/GoogleSignInButton';
import Loading from '@/components/Loading';
import {
  EMPTY_PAGE_ID,
  ENABLE_NEW_DASHBOARD,
  ENABLE_NEW_WORKSPACE,
  PROMO_SOURCE_STORAGE_EXPIRY,
  PROMO_SOURCE_STORAGE_KEY,
  SOURCE_STORAGE_KEY,
  SPACE_PERMISSION_STORAGE_KEY,
  USER_STORAGE_KEY,
} from '@/constants';
import { api } from '@/data/api';
import styles from '@/styles/Onboarding.module.css';
import type { IUser } from '@/types';
import { errToString } from '@/utils/errors';
import getSpacePageID from '@/utils/getSpacePageID';
import { setLocalStorageWithExpiry } from '@/utils/localStorageExpire';
import { CREATE_SITE_STEPS_ORDER } from '@/utils/loginSteps';
import useLoggedInRedirect from '@/utils/useLoggedInRedirect';
import { XCircle } from '@styled-icons/boxicons-solid';
import {
  AccessTokenHandler,
  GoogleToken,
  ISpace,
  ISpacePermission,
  PTokenHandler,
  STokenHandler,
  saveSpaceInLocalStorage,
} from '@typedream/data';
import type { AxiosResponse } from 'axios';
import classNames from 'classnames';
import mixpanel from 'mixpanel-browser';
import Image from 'next/image';
import { usePostHog } from 'posthog-js/react';
import { useCallback, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import formStyle from './FormStyle.module.css';
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';
import VerifyCode from './VerifyCode';
import type { LoginSignUpFormPropsType } from './type';

export default function LoginSignupForm({
  login,
  redirect,
  onSuccess,
  loginWithEmail = '',
  disableGoogleSigninPopup = false,
}: LoginSignUpFormPropsType) {
  const posthog = usePostHog();
  const [email, setEmail] = useState('');
  const [emailDisabled, setemailDisabled] = useState(false);
  const [err, seterr] = useState('');
  const [errEmail, seterrEmail] = useState(false);
  const [errSource, seterrSource] = useState(false);
  const [formSuccess, setformSuccess] = useState(false);
  const [inputSource, setInputSource] = useState('');
  const [inputSourceSpecific, setInputSourceSpecific] = useState('');
  const [loading, setloading] = useState(false);
  const [oauthJWT, setoauthJWT] = useState<GoogleToken>();
  const [googleEmail, setGoogleEmail] = useState('');
  const [isLoginWithGoogle, setIsLoginWithGoogle] = useState(false);
  const [showSourceField, setshowSourceField] = useState(false);

  const redirectLoading = useLoggedInRedirect(!formSuccess && redirect);
  const loginRedirectLoading = redirect ? redirectLoading : false;

  const handleWorkspaceRedirection = (newUrl: string, oldUrl: string) => {
    window.location.href = ENABLE_NEW_WORKSPACE ? newUrl : oldUrl;
  };

  useEffect(() => {
    if (!login) {
      posthog?.capture('view_sign_up');
    }
  }, [login]);

  const verifySuccessHandler = useCallback(
    async (
      r: AxiosResponse<{
        user: IUser;
        spaces: ISpace[];
        space_permissions: ISpacePermission[];
        token: string;
        new: boolean;
      }>,
      source: string
    ) => {
      const data = r?.data;

      AccessTokenHandler.storeTokens(data?.token);
      localStorage?.setItem(USER_STORAGE_KEY, JSON.stringify(data?.user));
      saveSpaceInLocalStorage(data?.spaces);
      localStorage?.setItem(SPACE_PERMISSION_STORAGE_KEY, JSON.stringify(data?.space_permissions));
      STokenHandler.removeTokens();
      PTokenHandler.removeTokens();

      const { defaultSpace, defaultSpaceID, defaultPageID, defaultSpaceSlug, altSpaceID } =
        getSpacePageID(false);

      const user = data?.user as IUser;
      if (data?.new) {
        // Mixpanel SIGN_UP event is done on the backend

        // Send Event to Google Tag Manager
        TagManager.dataLayer({
          dataLayer: {
            event: 'sign_up',
            user_data: {
              email: user?.email,
            },
          },
        });

        // identify user
        mixpanel.identify(user?.id);
        mixpanel.people.set({
          last_auth: new Date(),
        });
        mixpanel.register({
          number_of_sites: (r?.data?.spaces || []).length,
          user_persona: user?.metadata?.persona,
        });
        posthog?.identify(
          user?.id,
          { email: user?.email, name: user?.name },
          { created_at: user?.created_at }
        );
        // Posthog send sign up event
        posthog?.capture('sign_up');

        setLocalStorageWithExpiry(PROMO_SOURCE_STORAGE_KEY, source, PROMO_SOURCE_STORAGE_EXPIRY);

        if (onSuccess) {
          // indicate new user on success
          onSuccess(true);
          return;
        }
        handleWorkspaceRedirection(
          '/onboarding',
          `${CREATE_SITE_STEPS_ORDER[0].link}?onboarding=true`
        );
      } else {
        // Mixpanel LOG_IN event is done on the backend

        // Send Event to Google Tag Manager
        TagManager.dataLayer({
          dataLayer: {
            event: 'login',
            user_data: {
              email: user?.email,
            },
          },
        });

        mixpanel.identify(user?.id);
        mixpanel.people.set({
          last_auth: new Date(),
        });
        mixpanel.register({
          number_of_sites: (r?.data?.spaces || []).length,
          user_persona: user?.metadata?.persona,
        });
        posthog?.identify(user?.id, { email: user?.email, name: user?.name });
        window.CommandBar?.boot(user?.id);

        if (onSuccess) {
          onSuccess();
          setloading(false);
          return;
        }

        // Check if new user finish onboarding
        if (data?.spaces?.length === 0) {
          handleWorkspaceRedirection(
            '/onboarding',
            `${CREATE_SITE_STEPS_ORDER[0].link}?onboarding=true`
          );
          return;
        }
        if (!defaultSpaceID || !defaultPageID) {
          window.location.href = '/dashboard/sites';
          return;
        }

        if (ENABLE_NEW_DASHBOARD) {
          window.location.href = `/dashboard/${
            defaultPageID === EMPTY_PAGE_ID && altSpaceID ? altSpaceID : defaultSpaceID
          }`;
          return;
        }

        // TODO: Remove this after new dashboard is released
        if (defaultSpace?.metadata?.site_type === 'link_in_bio') {
          window.location.href = `/link/s/${defaultSpaceID}/${defaultPageID}`;
        } else {
          window.location.href = `/s/${defaultSpaceSlug}/${defaultSpaceID}/${defaultPageID}`;
        }
      }
    },
    [onSuccess]
  );

  const verify = useCallback(
    async (token: string, withOAuth: boolean) => {
      setloading(true);
      const source = localStorage?.getItem(SOURCE_STORAGE_KEY);
      localStorage?.removeItem(SOURCE_STORAGE_KEY);

      const data = {
        email,
        token,
        source: null,
        tracking_id: mixpanel.get_distinct_id(),
        metadata: null,
      };

      if (source && source !== 'undefined' && source !== 'null') {
        data.source = source;
      }

      if (!login) {
        const hearFrom =
          inputSource === 'Other' ? `${inputSource}:${inputSourceSpecific}` : inputSource;
        data.metadata = {
          hear_from: hearFrom,
        };
      }

      try {
        let r: AxiosResponse<any>;
        if (withOAuth) {
          r = await api().user().verifyGoogle(data);
        } else {
          r = await api().user().verify(data);
        }
        await verifySuccessHandler(r, source);
      } catch (e) {
        seterr(errToString(e));
        setloading(false);
      }
    },
    [email, inputSource, inputSourceSpecific, login, verifySuccessHandler]
  );

  function reload() {
    window.location.reload();
  }

  const onSubmit = useCallback(async () => {
    if (loading) return;
    if (!email && !oauthJWT?.payload?.email) {
      seterrEmail(true);
      return;
    }
    if (!login && (!inputSource || (inputSource === 'Other' && !inputSourceSpecific))) {
      seterrSource(true);
      return;
    }
    seterrEmail(false);
    seterrSource(false);
    setloading(true);
    seterr(null);
    try {
      const data = {
        email: email || oauthJWT?.payload?.email,
      };

      if (login) {
        await api().user().login(data, !!oauthJWT?.token);
      } else {
        await api().user().signup(data, !!oauthJWT?.token);
      }

      if (oauthJWT?.token) {
        verify(oauthJWT?.token, true);
      } else {
        setformSuccess(true);
      }
    } catch (e) {
      seterr(errToString(e));
      setformSuccess(false);
    } finally {
      setloading(false);
    }
  }, [
    email,
    inputSource,
    inputSourceSpecific,
    loading,
    login,
    oauthJWT?.payload?.email,
    oauthJWT?.token,
    verify,
  ]);

  useEffect(() => {
    seterrEmail(false);
    seterrSource(false);
  }, [email, oauthJWT, inputSource]);

  useEffect(() => {
    if (loginWithEmail) {
      setEmail(loginWithEmail);
      setemailDisabled(true);
    }
  }, [loginWithEmail]);

  useEffect(() => {
    if (isLoginWithGoogle && googleEmail) {
      if (login) {
        onSubmit();
      } else {
        setshowSourceField(true);
      }
      setIsLoginWithGoogle(false);
    }
  }, [login, googleEmail, onSubmit, isLoginWithGoogle]);

  // get OAuth Spesific value
  useEffect(() => {
    if (oauthJWT?.payload?.email) {
      setGoogleEmail(oauthJWT?.payload?.email);
      setIsLoginWithGoogle(true);
    }
  }, [oauthJWT?.payload?.email]);

  return (
    <>
      {!formSuccess ? (
        <div className={classNames('mb-2', styles.formContainer)}>
          <GoogleSignInButton
            login={login}
            setoauthJWT={setoauthJWT}
            disableGoogleSigninPopup={disableGoogleSigninPopup}
          />
          {oauthJWT && (
            <div className={formStyle.googleContinueContainer}>
              <div className="items-center">
                <Image src="/images/google.svg" alt="Google Logo" width={18} height={18} />
                <span className={formStyle.text}>
                  Continue as{' '}
                  <span className="color-[var(--text)]">{oauthJWT?.payload?.email}</span>
                </span>
              </div>
              <div role="none" onClick={reload}>
                <XCircle className="is-subtitle cursor-pointer" size={20} />
              </div>
            </div>
          )}

          {!oauthJWT && (
            <div
              className={classNames(
                'is-size-6 with-lighter-text-color my-5 text-center flex items-center',
                formStyle.or
              )}
            >
              <hr />
              <span className="mx-3">OR</span>
              <hr />
            </div>
          )}

          {login ? (
            <LoginForm
              email={email}
              emailDisabled={emailDisabled}
              errEmail={errEmail}
              loading={loading}
              onSubmit={onSubmit}
              setEmail={setEmail}
              showEmailField={!oauthJWT}
            />
          ) : (
            <SignupForm
              email={email}
              emailDisabled={emailDisabled}
              errEmail={errEmail}
              errSource={errSource}
              inputSource={inputSource}
              inputSourceSpecific={inputSourceSpecific}
              loading={loading}
              onSubmit={onSubmit}
              setEmail={setEmail}
              setInputSource={setInputSource}
              setInputSourceSpecific={setInputSourceSpecific}
              setshowSourceField={setshowSourceField}
              showEmailField={!oauthJWT}
              showSourceField={showSourceField}
            />
          )}
        </div>
      ) : (
        <VerifyCode
          email={email}
          err={err}
          loading={loading}
          onSubmit={onSubmit}
          setErr={seterr}
          verify={verify}
        />
      )}

      {err && (
        <div className={classNames(styles.error, 'has-background-danger-light has-text-danger')}>
          {err}
        </div>
      )}
      <Loading loading={loading || loginRedirectLoading} />
    </>
  );
}
