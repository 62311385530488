import { Button, Text, cn } from '@typedream/ui';
import type { FormEvent } from 'react';
import type { LoginFormPropsType } from './type';
import formStyle from '../FormStyle.module.css';

export default function LoginForm({
  email,
  setEmail,
  onSubmit,
  emailDisabled,
  loading,
  showEmailField,
  errEmail,
}: LoginFormPropsType) {
  const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <form onSubmit={handleOnSubmit}>
      {showEmailField && (
        <>
          <Text>Email</Text>
          <div
            className={cn(
              'mt-2 mx-0 mb-5 py-[15px] px-4 border border-border rounded-md border-solid',
              'flex items-center justify-between w-full bg-background',
              {
                'border-none bg-gray-100': emailDisabled,
                'border-red-100 border-2': errEmail,
              }
            )}
          >
            <input
              data-testid="Login_email_input"
              className={cn(
                formStyle.input,
                'bg-background placeholder:text-secondary text-primary'
              )}
              value={email || ''}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="Enter your email address to continue..."
              required
              disabled={emailDisabled}
            />
          </div>
        </>
      )}

      <div className="w-full flex items-center gap-2">
        <Button
          type="submit"
          isLoading={loading}
          isDisabled={!email && showEmailField}
          data-testid="Login_submit_email"
          className="block w-full"
          loadingText="Logging in..."
        >
          Login
        </Button>
      </div>
    </form>
  );
}
