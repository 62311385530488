import {
  EMPTY_PAGE_ID,
  ENABLE_NEW_DASHBOARD,
  ENABLE_NEW_WORKSPACE,
  USER_STORAGE_KEY,
} from '@/constants';
import { AccessTokenHandler, AccessTokenInterface } from '@typedream/data';
import mixpanel from 'mixpanel-browser';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { api } from '@/data/api';
import posthog from 'posthog-js';
import TagManager from 'react-gtm-module';
import getSpacePageID from './getSpacePageID';
import getUserID from './getUserID';
import { CREATE_SITE_STEPS_ORDER } from './loginSteps';

export default function useLoggedInRedirect(canRedirect: boolean) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // check if user is logged in
    const accessToken = AccessTokenHandler.getAccessToken();
    setIsLoggedIn(accessToken && accessToken.token.length > 0);
  }, []);

  const [loading, setloading] = useState(true);
  const router = useRouter();

  const handleWorkspaceRedirection = (newUrl: string, oldUrl: string) => {
    window.location.href = ENABLE_NEW_WORKSPACE ? newUrl : oldUrl;
  };

  const redirect = async (_isLoggedIn: boolean, _canRedirect: boolean) => {
    const queryParam = router?.query;
    // User clicked "Login Here", don't redirect, instead
    // let it be handled by the cotter form
    if (queryParam?.auth_method && queryParam?.challenge) {
      return;
    }
    if (_isLoggedIn && _canRedirect) {
      // Check if the user has `uid`, this might be a race condition between
      // isLoggedIn becoming true after the user approved magic link,
      // and _canRedirect hasn't been updated
      // (onSignupSuccess is not yet run)
      const accessToken = AccessTokenHandler.getAccessToken();
      const payload = accessToken?.payload as AccessTokenInterface;
      if (!payload?.UID) {
        setloading(false);
        return;
      }

      setloading(true);
      const userID = getUserID();
      try {
        mixpanel.identify(userID);

        await api()
          .user()
          .get()
          .then((res) => {
            const user = res.data;
            localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
            mixpanel.register({
              user_id: user?.id,
              user_persona: user?.metadata?.persona,
            });

            posthog?.identify(userID, { email: user?.email, name: user?.name });

            // Send Event to Google Tag Manager
            TagManager.dataLayer({
              dataLayer: {
                event: 'logged_in',
                user_data: {
                  email: user?.email,
                },
              },
            });

            window.CommandBar?.boot(userID);
          });
      } catch (e) {
        // do nothing if can't call mixpanel.identify
      }

      const { noSpaces, defaultSpaceID, defaultPageID, defaultSpaceSlug, spacesCount, altSpaceID } =
        getSpacePageID(false);

      if (noSpaces || (spacesCount === 1 && defaultPageID === EMPTY_PAGE_ID)) {
        handleWorkspaceRedirection(
          '/onboarding',
          `${CREATE_SITE_STEPS_ORDER[0].link}?onboarding=true`
        );
        return;
      }

      if (!defaultSpaceID || !defaultPageID) {
        window.location.href = '/dashboard/sites';
        return;
      }

      // TODO:LINKINBIO
      // if (defaultSpace?.metadata?.site_type === 'link_in_bio') {
      //   window.location.href = `/link/s/${defaultSpaceID}/${defaultPageID}`;
      // } else {
      //   window.location.href = `/s/${defaultSpaceSlug}/${defaultSpaceID}/${defaultPageID}`;
      // }

      if (ENABLE_NEW_DASHBOARD) {
        window.location.href = `/dashboard/${
          defaultPageID === EMPTY_PAGE_ID && altSpaceID ? altSpaceID : defaultSpaceID
        }`;
        return;
      }

      window.location.href = `/s/${defaultSpaceSlug}/${defaultSpaceID}/${defaultPageID}`;
    } else if (_canRedirect) {
      setloading(false);
    }
  };

  useEffect(() => {
    redirect(isLoggedIn, canRedirect);
  }, [isLoggedIn, canRedirect]);

  return loading;
}
