const CREATE_SITE_STEPS = {
  welcome: { key: 'welcome', link: '/login/welcome' },
  profile: { key: 'profile', link: '/login/profile' },
  theme: { key: 'theme', link: '/login/theme' },
  space: { key: 'space', link: '/login/space' },
  subscribe: { key: 'subscribe', link: '/login/subscribe' },
  option: { key: 'option', link: '/sites/option' },
  type: { key: 'type', link: '/sites/type' },
  template: { key: 'template', link: '/sites/template' },
  pages: { key: 'pages', link: '/sites/pages' },
  create: { key: 'create', link: '/onboarding' },
};

export const CREATE_SITE_STEPS_ORDER = [
  CREATE_SITE_STEPS.option,
  CREATE_SITE_STEPS.type,
  CREATE_SITE_STEPS.template,
  CREATE_SITE_STEPS.pages,
  CREATE_SITE_STEPS.create,
];

export default CREATE_SITE_STEPS;
