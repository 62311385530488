import GoogleSignInScript from '@/head/GoogleSignInScript';
import useWindowSize from '@/utils/useWindowSize';
import { GoogleToken, JwtToken } from '@typedream/data';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import { GOOGLE_CLIENT_ID } from '@/constants';

function GoogleSignInButton({
  setoauthJWT,
  login,
  disableGoogleSigninPopup = false,
}: {
  setoauthJWT: Dispatch<SetStateAction<JwtToken>>;
  login: boolean;
  disableGoogleSigninPopup?: boolean;
}) {
  const size = useWindowSize();
  const [showButton, setshowButton] = useState(true);

  const divRef = useRef(null);

  function renderButton() {
    if (window.google) {
      if (!disableGoogleSigninPopup) {
        window.google?.accounts?.id?.prompt(); // also display the One Tap dialog
      }
      window.google?.accounts?.id?.renderButton(divRef.current, {
        size: 'large',
        width: window.screen.width <= 650 ? window.screen.width - 32 : '100%',
        logo_alignment: 'center',
      });
    }
  }

  useEffect(() => {
    renderButton();
  }, [size?.width]);

  useEffect(() => {
    setshowButton(true);
  }, [login]);

  function handleCredentialResponse(response) {
    const credJWT = new GoogleToken(response.credential);
    setoauthJWT(credJWT);

    // Don't use js removeChild so we can re-show the button
    // if `login` parameter changes
    setshowButton(false);
  }

  function loadGoogleSignIn() {
    if (window.google) {
      window.google?.accounts?.id?.initialize({
        client_id: GOOGLE_CLIENT_ID,
        callback: handleCredentialResponse,
        auto_select: false,
        prompt_parent_id: 'oneTap',
      });
    }
  }

  useEffect(() => {
    if (window && divRef?.current) {
      const googleScript = document.getElementById('google-sign-in-script');
      // if (window?.google) {
      //   loadGoogleSignIn();
      //   renderButton();
      // }
      googleScript?.addEventListener('load', () => {
        loadGoogleSignIn();
        renderButton();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divRef?.current]);

  return (
    <>
      <GoogleSignInScript />
      {showButton && <div id="buttonDiv" ref={divRef} />}
    </>
  );
}

export default GoogleSignInButton;
